import * as i0 from '@angular/core';
import { inject, effect, Directive, Input, Component, ChangeDetectionStrategy, HostBinding, input, computed } from '@angular/core';
import { CrmUnsubscribeDirective, CrmBreakpointSpanDirective, CrmMergeObjectsPipe } from 'common-module/core';
import { CrmFormStore } from 'common-module/form/store';
import { Subject, combineLatest, switchMap, of, tap } from 'rxjs';
import { filter, map, debounceTime } from 'rxjs/operators';
import { NgStyle } from '@angular/common';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzFormItemComponent, NzFormLabelComponent, NzFormControlComponent } from 'ng-zorro-antd/form';
import { NzRowDirective } from 'ng-zorro-antd/grid';
import { toObservable, takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CrmRendererDirective } from 'common-module/renderer';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
const _c0 = ["*"];
function CrmFormDataWrapperComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-form-label", 1);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    let tmp_3_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("breakpointConfig", (tmp_1_0 = ctx_r0.config.layout == null ? null : ctx_r0.config.layout.label) !== null && tmp_1_0 !== undefined ? tmp_1_0 : 24)("nzNoColon", ctx_r0.config.layout == null ? null : ctx_r0.config.layout.noColon)("nzLabelAlign", (tmp_3_0 = ctx_r0.config.layout == null ? null : ctx_r0.config.layout.labelAlign) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "right");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 4, ctx_r0.config.label), " ");
  }
}
const _c1 = (a0, a1, a2) => ({
  form: a0,
  name: a1,
  data: a2
});
const _c2 = a0 => ({
  children: a0
});
const _c3 = (a0, a1, a2, a3) => ({
  form: a0,
  name: a1,
  data: a2,
  config: a3
});
function CrmFormFragmentComponent_Conditional_0_Case_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("crmRenderer", ctx_r0.fragment())("additionalContext", i0.ɵɵpureFunction3(2, _c1, ctx_r0.form(), ctx_r0.fragment().name, ctx_r0.data()));
  }
}
function CrmFormFragmentComponent_Conditional_0_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 0);
    i0.ɵɵpipe(1, "crmMergeObjects");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("crmRenderer", ctx_r0.fragment())("additionalContext", i0.ɵɵpureFunction4(7, _c3, ctx_r0.form(), ctx_r0.fragment().name, ctx_r0.data(), i0.ɵɵpipeBind2(1, 2, ctx_r0.context(), i0.ɵɵpureFunction1(5, _c2, ctx_r0.fragment().children))));
  }
}
function CrmFormFragmentComponent_Conditional_0_Case_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 0);
    i0.ɵɵpipe(1, "crmMergeObjects");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("crmRenderer", ctx_r0.fragment())("additionalContext", i0.ɵɵpureFunction4(7, _c3, ctx_r0.form(), ctx_r0.fragment().name, ctx_r0.data(), i0.ɵɵpipeBind2(1, 2, ctx_r0.context(), i0.ɵɵpureFunction1(5, _c2, ctx_r0.fragment().children))));
  }
}
function CrmFormFragmentComponent_Conditional_0_Case_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("crmRenderer", ctx_r0.fragment());
  }
}
function CrmFormFragmentComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmFormFragmentComponent_Conditional_0_Case_0_Template, 1, 6)(1, CrmFormFragmentComponent_Conditional_0_Case_1_Template, 2, 12)(2, CrmFormFragmentComponent_Conditional_0_Case_2_Template, 2, 12)(3, CrmFormFragmentComponent_Conditional_0_Case_3_Template, 1, 1);
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, (tmp_1_0 = ctx_r0.fragment().type) === "data-input" ? 0 : tmp_1_0 === "group" ? 1 : tmp_1_0 === "array" ? 2 : tmp_1_0 === "design" ? 3 : -1);
  }
}
const _forTrack0 = ($index, $item) => $item.name;
function CrmFormGroupComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-divider", 0);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    let tmp_1_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzOrientation", (tmp_1_0 = ctx_r0.divider.orientation) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "left")("nzText", i0.ɵɵpipeBind1(1, 2, ctx_r0.divider.text));
  }
}
function CrmFormGroupComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "crm-form-fragment", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_10_0;
    const child_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("breakpointConfig", (tmp_10_0 = child_r2.layout == null ? null : child_r2.layout.span) !== null && tmp_10_0 !== undefined ? tmp_10_0 : 24);
    i0.ɵɵadvance();
    i0.ɵɵproperty("form", ctx_r0.group)("data", ctx_r0.data == null ? null : ctx_r0.data[child_r2.name])("fragment", child_r2);
  }
}
const _c4 = (a0, a1, a2) => ({
  control: a0,
  name: a1,
  data: a2
});
const _c5 = () => ({});
function CrmFormArrayComponent_For_2_Conditional_0_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 2);
  }
  if (rf & 2) {
    const fragment_r1 = i0.ɵɵnextContext();
    const ctx_r1 = i0.ɵɵnextContext();
    const control_r3 = ctx_r1.$implicit;
    const idx_r4 = ctx_r1.$index;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("crmRenderer", fragment_r1)("additionalContext", i0.ɵɵpureFunction3(2, _c4, control_r3, fragment_r1.name, ctx_r4.data == null ? null : ctx_r4.data[idx_r4]));
  }
}
function CrmFormArrayComponent_For_2_Conditional_0_Case_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 2);
    i0.ɵɵpipe(1, "crmMergeObjects");
  }
  if (rf & 2) {
    let tmp_14_0;
    const fragment_r1 = i0.ɵɵnextContext();
    const ctx_r1 = i0.ɵɵnextContext();
    const control_r3 = ctx_r1.$implicit;
    const idx_r4 = ctx_r1.$index;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("crmRenderer", fragment_r1)("additionalContext", i0.ɵɵpureFunction4(8, _c3, control_r3, fragment_r1.name, ctx_r4.data == null ? null : ctx_r4.data[idx_r4] == null ? null : ctx_r4.data[idx_r4][fragment_r1.name], i0.ɵɵpipeBind2(1, 2, (tmp_14_0 = fragment_r1.contentContext) !== null && tmp_14_0 !== undefined ? tmp_14_0 : i0.ɵɵpureFunction0(5, _c5), i0.ɵɵpureFunction1(6, _c2, fragment_r1.children))));
  }
}
function CrmFormArrayComponent_For_2_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, CrmFormArrayComponent_For_2_Conditional_0_Case_1_Template, 1, 6)(2, CrmFormArrayComponent_For_2_Conditional_0_Case_2_Template, 2, 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_12_0;
    let tmp_13_0;
    const fragment_r1 = ctx;
    i0.ɵɵproperty("breakpointConfig", (tmp_12_0 = fragment_r1.layout == null ? null : fragment_r1.layout.span) !== null && tmp_12_0 !== undefined ? tmp_12_0 : 24);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, (tmp_13_0 = fragment_r1.type) === "data-input" ? 1 : tmp_13_0 === "group" ? 2 : -1);
  }
}
function CrmFormArrayComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmFormArrayComponent_For_2_Conditional_0_Template, 3, 2, "div", 1);
  }
  if (rf & 2) {
    let tmp_10_0;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, (tmp_10_0 = ctx_r4.config.children[0]) ? 0 : -1, tmp_10_0);
  }
}
class CrmFormDataInputComponent extends CrmUnsubscribeDirective {
  constructor() {
    super();
    this.errorSubject$ = new Subject();
    this.mode = inject(CrmFormStore).mode;
    effect(() => this.onModeChange(this.mode()), {
      allowSignalWrites: true
    });
  }
  ngOnInit() {
    this.initControl();
    this.error$ = this.errorSubject$.asObservable();
  }
  initControl() {
    if (!this.control) {
      this.control = this.form.controls[this.name];
    }
    this.config ??= {};
    this.status$ = this.untilDestroyed(this.control.statusChanges).pipe(filter(() => this.control.dirty), map(status => {
      switch (status) {
        case 'INVALID':
          this.emitError();
          return 'error';
        case 'PENDING':
          return 'validating';
        default:
          return 'success';
      }
    }));
  }
  emitError() {
    const error = Object.keys(this.control.errors ?? {})[0];
    if (!error) {
      this.errorSubject$.next(null);
    }
    const {
      validators = [],
      asyncValidators = []
    } = this.config;
    const validator = validators?.find(v => v.name === error);
    if (validator) {
      this.errorSubject$.next(validator.error);
      return;
    }
    const asyncValidator = asyncValidators?.find(v => v.name === error);
    if (asyncValidator) {
      this.errorSubject$.next(asyncValidator.error);
      return;
    }
  }
  onModeChange(mode) {
    switch (mode) {
      case 'read':
        this.onReadMode();
        break;
      case 'edit':
        this.onEditMode();
        break;
    }
  }
  onReadMode() {
    this.control?.setValue(this.data, {
      onlySelf: true
    });
    if (this.config.changeDisabledStateOnModeChange) {
      this.control?.disable({
        onlySelf: true
      });
    }
    this.control?.markAsPristine();
  }
  onEditMode() {
    if (this.config.changeDisabledStateOnModeChange) {
      this.control?.enable({
        onlySelf: true
      });
    }
  }
  static {
    this.ɵfac = function CrmFormDataInputComponent_Factory(t) {
      return new (t || CrmFormDataInputComponent)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmFormDataInputComponent,
      inputs: {
        form: "form",
        name: "name",
        data: "data",
        config: "config",
        control: "control"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDataInputComponent, [{
    type: Directive
  }], () => [], {
    form: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    control: [{
      type: Input
    }]
  });
})();
class CrmFormDataWrapperComponent {
  constructor() {
    this.hostClass = 'crm-form-data-input-wrapper';
  }
  static {
    this.ɵfac = function CrmFormDataWrapperComponent_Factory(t) {
      return new (t || CrmFormDataWrapperComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormDataWrapperComponent,
      selectors: [["crm-form-data-input-wrapper"]],
      hostVars: 2,
      hostBindings: function CrmFormDataWrapperComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        status: "status",
        error: "error",
        config: "config"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 5,
      vars: 10,
      consts: [[1, "crm-form-item", 3, "nzJustify", "nzAlign", "nzGutter", "ngStyle"], ["crmBreakpointSpan", "", 1, "crm-form-data-input-label", 3, "breakpointConfig", "nzNoColon", "nzLabelAlign"], ["crmBreakpointSpan", "", 1, "crm-form-data-input-control", 3, "nzValidateStatus", "nzErrorTip", "breakpointConfig"]],
      template: function CrmFormDataWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "nz-form-item", 0);
          i0.ɵɵtemplate(1, CrmFormDataWrapperComponent_Conditional_1_Template, 3, 6, "nz-form-label", 1);
          i0.ɵɵelementStart(2, "nz-form-control", 2);
          i0.ɵɵpipe(3, "crmTranslate");
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_0_0;
          let tmp_1_0;
          let tmp_2_0;
          let tmp_5_0;
          let tmp_7_0;
          i0.ɵɵproperty("nzJustify", (tmp_0_0 = ctx.config.layout == null ? null : ctx.config.layout.justify) !== null && tmp_0_0 !== undefined ? tmp_0_0 : null)("nzAlign", (tmp_1_0 = ctx.config.layout == null ? null : ctx.config.layout.align) !== null && tmp_1_0 !== undefined ? tmp_1_0 : null)("nzGutter", (tmp_2_0 = ctx.config.layout == null ? null : ctx.config.layout.gutter) !== null && tmp_2_0 !== undefined ? tmp_2_0 : 0)("ngStyle", ctx.config.layout == null ? null : ctx.config.layout.style);
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, ctx.config.label ? 1 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("nzValidateStatus", (tmp_5_0 = ctx.status) !== null && tmp_5_0 !== undefined ? tmp_5_0 : "")("nzErrorTip", i0.ɵɵpipeBind1(3, 8, ctx.error))("breakpointConfig", (tmp_7_0 = ctx.config.layout == null ? null : ctx.config.layout.input) !== null && tmp_7_0 !== undefined ? tmp_7_0 : 24);
        }
      },
      dependencies: [CrmTranslatePipe, NzFormItemComponent, NzRowDirective, NgStyle, NzFormLabelComponent, CrmBreakpointSpanDirective, NzFormControlComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDataWrapperComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-data-input-wrapper',
      template: `
    <nz-form-item
      [nzJustify]="config.layout?.justify ?? null"
      [nzAlign]="config.layout?.align ?? null"
      [nzGutter]="config.layout?.gutter ?? 0"
      class="crm-form-item"
      [ngStyle]="config.layout?.style"
    >
      @if (config.label) {
        <nz-form-label
          class="crm-form-data-input-label"
          crmBreakpointSpan
          [breakpointConfig]="config.layout?.label ?? 24"
          [nzNoColon]="config.layout?.noColon"
          [nzLabelAlign]="config.layout?.labelAlign ?? 'right'"
        >
          {{ config.label | crmTranslate }}
        </nz-form-label>
      }

      <nz-form-control
        class="crm-form-data-input-control"
        [nzValidateStatus]="status ?? ''"
        [nzErrorTip]="error | crmTranslate"
        crmBreakpointSpan
        [breakpointConfig]="config.layout?.input ?? 24"
      >
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
      imports: [CrmTranslatePipe, NzFormItemComponent, NzRowDirective, NgStyle, NzFormLabelComponent, CrmBreakpointSpanDirective, NzFormControlComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    status: [{
      type: Input
    }],
    error: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormFragmentComponent {
  constructor() {
    this.hostClass = 'crm-form-fragment';
    this.form = input.required();
    this.fragment = input.required();
    this.data = input();
    this.context = computed(() => this.fragment().contentContext ?? {});
    this.visible$ = combineLatest({
      form: toObservable(this.form),
      fragment: toObservable(this.fragment)
    }).pipe(debounceTime(0), switchMap(({
      fragment,
      form
    }) => {
      if (!fragment.visible$) {
        return of(true);
      }
      return fragment.visible$(form).pipe(tap(visible => {
        const control = form.controls[fragment.name];
        if (!control) {
          return;
        }
        if (visible) {
          control.enable({
            emitEvent: false
          });
        } else {
          control.disable({
            emitEvent: false
          });
          control.reset();
        }
      }));
    }), takeUntilDestroyed());
    this.visible = toSignal(this.visible$);
  }
  static {
    this.ɵfac = function CrmFormFragmentComponent_Factory(t) {
      return new (t || CrmFormFragmentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormFragmentComponent,
      selectors: [["crm-form-fragment"]],
      hostVars: 2,
      hostBindings: function CrmFormFragmentComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        form: [i0.ɵɵInputFlags.SignalBased, "form"],
        fragment: [i0.ɵɵInputFlags.SignalBased, "fragment"],
        data: [i0.ɵɵInputFlags.SignalBased, "data"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[3, "crmRenderer", "additionalContext"], [3, "crmRenderer"]],
      template: function CrmFormFragmentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmFormFragmentComponent_Conditional_0_Template, 4, 1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.visible() ? 0 : -1);
        }
      },
      dependencies: [CrmRendererDirective, CrmMergeObjectsPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormFragmentComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-fragment',
      template: `
    @if (visible()) {
      @switch (fragment().type) {
        @case ('data-input') {
          <ng-container
            [crmRenderer]="fragment()"
            [additionalContext]="{
              form: form(),
              name: fragment().name,
              data: data()
            }"
          ></ng-container>
        }
        @case ('group') {
          <ng-container
            [crmRenderer]="fragment()"
            [additionalContext]="{
              form: form(),
              name: fragment().name,
              data: data(),
              config:
                context() | crmMergeObjects: { children: fragment().children }
            }"
          ></ng-container>
        }
        @case ('array') {
          <ng-container
            [crmRenderer]="fragment()"
            [additionalContext]="{
              form: form(),
              name: fragment().name,
              data: data(),
              config:
                context() | crmMergeObjects: { children: fragment().children }
            }"
          ></ng-container>
        }
        @case ('design') {
          <ng-container [crmRenderer]="fragment()"></ng-container>
        }
      }
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmRendererDirective, CrmMergeObjectsPipe]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormGroupComponent {
  constructor() {
    this.hostClass = 'crm-form-group';
  }
  ngOnInit() {
    this.group = this.form.controls[this.name];
  }
  static {
    this.ɵfac = function CrmFormGroupComponent_Factory(t) {
      return new (t || CrmFormGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormGroupComponent,
      selectors: [["crm-form-group"]],
      hostVars: 2,
      hostBindings: function CrmFormGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        form: "form",
        name: "name",
        data: "data",
        divider: "divider",
        config: "config"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [[3, "nzOrientation", "nzText"], ["nz-row", "", 3, "nzGutter", "nzAlign", "nzJustify"], ["crmBreakpointSpan", "", 3, "breakpointConfig"], [3, "form", "data", "fragment"]],
      template: function CrmFormGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmFormGroupComponent_Conditional_0_Template, 2, 4, "nz-divider", 0);
          i0.ɵɵelementStart(1, "div", 1);
          i0.ɵɵrepeaterCreate(2, CrmFormGroupComponent_For_3_Template, 2, 4, "div", 2, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_1_0;
          let tmp_2_0;
          let tmp_3_0;
          i0.ɵɵconditional(0, ctx.divider ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("nzGutter", (tmp_1_0 = ctx.config.layout == null ? null : ctx.config.layout.gutter) !== null && tmp_1_0 !== undefined ? tmp_1_0 : 16)("nzAlign", (tmp_2_0 = ctx.config.layout == null ? null : ctx.config.layout.align) !== null && tmp_2_0 !== undefined ? tmp_2_0 : null)("nzJustify", (tmp_3_0 = ctx.config.layout == null ? null : ctx.config.layout.justify) !== null && tmp_3_0 !== undefined ? tmp_3_0 : null);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.config.children);
        }
      },
      dependencies: [CrmFormFragmentComponent, CrmTranslatePipe, NzDividerComponent, NzRowDirective, CrmBreakpointSpanDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormGroupComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-group',
      template: `
    @if (divider) {
      <nz-divider
        [nzOrientation]="divider.orientation ?? 'left'"
        [nzText]="divider.text | crmTranslate"
      ></nz-divider>
    }

    <div
      nz-row
      [nzGutter]="config.layout?.gutter ?? 16"
      [nzAlign]="config.layout?.align ?? null"
      [nzJustify]="config.layout?.justify ?? null"
    >
      @for (child of config.children; track child.name) {
        <div crmBreakpointSpan [breakpointConfig]="child.layout?.span ?? 24">
          <crm-form-fragment
            [form]="group"
            [data]="data?.[child.name]"
            [fragment]="child"
          ></crm-form-fragment>
        </div>
      }
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmFormFragmentComponent, CrmTranslatePipe, NzDividerComponent, NzRowDirective, CrmBreakpointSpanDirective]
    }]
  }], null, {
    form: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    divider: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns group form fragment
 *
 * @param name
 * @param children
 * @param divider
 * @param layout
 * @param visible$
 */
const crmGetFormGroup = ({
  name,
  children,
  divider,
  layout,
  visible$
}) => ({
  type: 'group',
  name,
  content: CrmFormGroupComponent,
  contentContext: {
    divider
  },
  children,
  layout,
  visible$
});
class CrmFormArrayComponent {
  constructor() {
    this.hostClass = 'crm-form-array';
  }
  ngOnInit() {
    this.array = this.form.controls[this.name];
  }
  static {
    this.ɵfac = function CrmFormArrayComponent_Factory(t) {
      return new (t || CrmFormArrayComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormArrayComponent,
      selectors: [["crm-form-array"]],
      hostVars: 2,
      hostBindings: function CrmFormArrayComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        form: "form",
        name: "name",
        data: "data",
        config: "config"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 3,
      consts: [["nz-row", "", 3, "nzGutter", "nzAlign", "nzJustify"], ["crmBreakpointSpan", "", 3, "breakpointConfig"], [3, "crmRenderer", "additionalContext"]],
      template: function CrmFormArrayComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵrepeaterCreate(1, CrmFormArrayComponent_For_2_Template, 1, 1, null, null, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_0_0;
          let tmp_1_0;
          let tmp_2_0;
          i0.ɵɵproperty("nzGutter", (tmp_0_0 = ctx.config.layout == null ? null : ctx.config.layout.gutter) !== null && tmp_0_0 !== undefined ? tmp_0_0 : 16)("nzAlign", (tmp_1_0 = ctx.config.layout == null ? null : ctx.config.layout.align) !== null && tmp_1_0 !== undefined ? tmp_1_0 : null)("nzJustify", (tmp_2_0 = ctx.config.layout == null ? null : ctx.config.layout.justify) !== null && tmp_2_0 !== undefined ? tmp_2_0 : null);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.array.controls);
        }
      },
      dependencies: [CrmRendererDirective, CrmMergeObjectsPipe, NzRowDirective, CrmBreakpointSpanDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormArrayComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-array',
      template: `
    <div
      nz-row
      [nzGutter]="config.layout?.gutter ?? 16"
      [nzAlign]="config.layout?.align ?? null"
      [nzJustify]="config.layout?.justify ?? null"
    >
      @for (control of array.controls; track idx; let idx = $index) {
        @if (config.children[0]; as fragment) {
          <div
            crmBreakpointSpan
            [breakpointConfig]="fragment.layout?.span ?? 24"
          >
            @switch (fragment.type) {
              @case ('data-input') {
                <ng-container
                  [crmRenderer]="fragment"
                  [additionalContext]="{
                    control: control,
                    name: fragment.name,
                    data: data?.[idx]
                  }"
                ></ng-container>
              }
              @case ('group') {
                <ng-container
                  [crmRenderer]="fragment"
                  [additionalContext]="{
                    form: control,
                    name: fragment.name,
                    data: data?.[idx]?.[fragment.name],
                    config:
                      fragment.contentContext ?? {}
                      | crmMergeObjects: { children: fragment.children }
                  }"
                ></ng-container>
              }
            }
          </div>
        }
      }
    </div>
  `,
      imports: [CrmRendererDirective, CrmMergeObjectsPipe, NzRowDirective, CrmBreakpointSpanDirective],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    form: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns array form fragment
 *
 * @param name
 * @param children
 * @param prefilled
 * @param layout
 * @param visible$
 */
const crmGetFormArray = ({
  name,
  children,
  prefilled,
  layout,
  visible$
}) => ({
  type: 'group',
  name,
  content: CrmFormArrayComponent,
  children,
  prefilled,
  layout,
  visible$
});

/**
 * Generated bundle index. Do not edit.
 */

export { CrmFormArrayComponent, CrmFormDataInputComponent, CrmFormDataWrapperComponent, CrmFormFragmentComponent, CrmFormGroupComponent, crmGetFormArray, crmGetFormGroup };
